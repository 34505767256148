import axios from 'axios';
import { URLS } from "./URLS";

export const getElements = async () => {
  return axios.get(URLS.ELEMENT)
    .then(p => p.data);
}

export const getElement = async (id, data) => {
  return axios.get(`${URLS.ELEMENT}/${id}`, data)
    .then(p => p.data);
}

export const createElement = async (data) => {
  return axios.post(URLS.ELEMENT, data)
    .then(p => p.data);
}

export const updateAvailability = async (id, data) => {
  return axios.put(`${URLS.AVAILABILITY}/${id}`, data).then(p => p.data);
}

export const updateElement = async (id, data) => {
  return axios.put(`${URLS.ELEMENT}/${id}`, data).then(p => p.data);
}
export const updateTeam = async (id, data) => {
  return axios.put(`${URLS.TEAM}/${id}`, data).then(p => p.data);
}

export const deleteElement = async (id) => {
  return axios.delete(`${URLS.ELEMENT}/${id}`).then(p => p.data);
}

export const getAvailability = async (id, timezone, data) => {
  return axios.get(`${URLS.AVAILABILITY}/${id}?timezone=${timezone}`, data)
    .then(p => p.data);
}

export const getAllAvailability = async (id, data) => {
  return axios.get(`${URLS.AVAILABILITY}`, data)
    .then(p => p.data);
}

export const getTeam = async (id, data) => {
  return axios.get(`${URLS.TEAM}/${id}`, data)
    .then(p => p.data);
}

export const getTeams = async (data) => {
  return axios.get(`${URLS.TEAM}`, data)
    .then(p => p.data);
}
