import axios from 'axios';
import { URLS } from "./URLS";

export const authenticateWithGoogle = async (data) => {
  return axios.post(URLS.GOOGLE_AUTH, data)
    .then(p => {
      axios.defaults.headers['Authorization'] = `Bearer ${p.data.user.accessToken}`;
      return p.data;
    });
}
