import React from 'react';
import "./HomeSidebar.css";
import { images, toastError } from "../../../utils/constants";
import { Input, Spinner } from 'reactstrap';
import { getCalendar } from '../../../apis/calendars';
import { getAvailability, getElement } from '../../../apis/element';

export default function HomeSidebar({setIsSelectedCalendarLoading, setIsSelectedElementLoading,isSmallScreen, selectedElement, selectedCalendar, handleSelectTeam, setSelectedAvailability, searchCalendar, isLoadingElement, isLoadingCalendar, handleNavigation, setSelectedCalendar, calendars, elements, userLogin, setSelectedElement }) {
    const getSelectedCalendarFromId = (calendarId) => {
        setIsSelectedCalendarLoading(true);
        getCalendar(calendarId)
            .then(({ data }) => {
                if (data) {
                    setSelectedCalendar(data);
                    handleNavigation("2", "right", `/calendar/${data.name}`);
                    // isSmallScreen ? handleNavigation("2", "right", `/calendar/${data.name}`) : handleNavigation("3", "left", `/calendar/${data.name}/meetingdetails`)
                    setSelectedElement(null);
                }
                setIsSelectedCalendarLoading(false);
            }).catch(err => {
                setIsSelectedCalendarLoading(false);
                toastError(err.response?.data?.errors, "bottom-right");
            });
    }

    const getSelectedElementFromId = async (element) => {
        setIsSelectedElementLoading(true);
        if (element.type === "video-meeting") {
            getElement(element.id, { name: element.name }).then(({ data }) => {
                setSelectedElement(data);
                setIsSelectedElementLoading(false);
                handleNavigation("2", "right", "/elementdetails");
            }).catch(err => {
                setIsSelectedElementLoading(false);
                toastError(err.response?.data?.errors, "bottom-right");
            })
        } else if (element.type === "availability") {
            getAvailability(element.id, Intl.DateTimeFormat().resolvedOptions().timeZone, { name: element.name })
            .then(({ data }) => {
                setSelectedElement(data);
                setIsSelectedElementLoading(false);
                setSelectedAvailability(data || element);
                isSmallScreen ? handleNavigation("2", "right", "/availability") : handleNavigation("3", "left", "/availability/timeslots")
                }).catch(err => {
                    setIsSelectedElementLoading(false);
                    toastError(err.response?.data?.errors, "bottom-right");
                })
        } else {
            handleSelectTeam(element.id, { name: element.name });
        }
        setSelectedCalendar(null);
    }

    const navigateToNewCalendar = () => {
        handleNavigation("2", "right", "/newcalendar");
        setSelectedCalendar(null);
        setSelectedElement(null);
    }

    const navigateToNewElement = () => {
        handleNavigation("2", "right", "/newelement")
        setSelectedCalendar(null);
        setSelectedElement(null);
    }
    const navigateToProfile = () => {
        handleNavigation("2", "right", "/profiledetails");
        setSelectedCalendar(null);
        setSelectedElement(null);
    }

    return (
        <div className='home-sidebar-container'>
            <div className="mb-3">
                {userLogin && <div onClick={navigateToProfile}
                    className="profile-card mb-3">
                    <div className='profile-image-container'>
                        <img className="profile-image" src={userLogin.photo} alt="pic" />
                    </div>
                    <label className="ms-5 mb-0 profile-name">{userLogin.name}</label>
                    <div className="profile-direction">
                        <img src={images.angleRightIcon} alt="angle_right" />
                    </div>
                </div>}

                <div className="search-box mb-3">
                    <div className="p-2 d-flex">
                        <img src={images.searchIcon} alt="search_icon" />
                    </div>
                    <Input className="border-0 px-0" placeholder="Search" onChange={(e) => searchCalendar(e.target.value)} />
                </div>
                <div className='element-heading mb-3'>
                    <label className="lbl-calendar-component mb-0">Calendars</label>
                    <button className="add-icon" onClick={navigateToNewCalendar}>
                        <img src={images.addIcon} alt="add_icon" />
                    </button>
                </div>

                <div className='calendar-container mb-3'>
                    {isLoadingCalendar ? <div className='d-flex justify-content-center'>
                        <Spinner color="primary" />
                    </div>
                        : !calendars.length ?
                            <div className='d-flex justify-content-center'>
                                <p className='mb-0'>No calendar found</p>
                            </div> :
                            calendars.map((calendar, i) => {
                                const icon = calendar.type === "individual" ? images.calendarIcon : images.collectiveIcon;
                                return (
                                    <div key={calendar.id} onClick={() => getSelectedCalendarFromId(calendar.id)}
                                        className={`${!i && "top-radius"} ${i === calendars.length - 1 && "bottom-radius"}
                                     calendar-item ${selectedCalendar && selectedCalendar.id === calendar.id ? "selected" : ""}`}>
                                        <div className='calendar-icon icon-container'>
                                            <img src={icon} alt="..." />
                                        </div>
                                        <div className={`calendar-title-container ${(calendars.length - 1) === i && "border-0"}`}>
                                            <h6 className="calendar-item-title mb-0">
                                                {calendar.name}
                                            </h6>
                                            <div className="direction-right pe-3">
                                                <img src={images.angleRightIcon} alt="angle_right" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                </div>
                <div className='element-heading mb-3'>
                    <label className="lbl-element-component mb-0">Elements</label>
                    <button className="add-icon" onClick={navigateToNewElement}>
                        <img src={images.addIcon} alt="add_icon" />
                    </button>
                </div>
                <div className='element-container'>
                    {isLoadingElement ?
                        <div className='d-flex justify-content-center'>
                            <Spinner color="primary" />
                        </div> : !elements.length ? <div className='d-flex justify-content-center'>
                            <p className='mb-0'>No record found</p>
                        </div> :
                            elements.map((element, i) => {
                                const icon = element.type === "team" || !element.type
                                    ? images.teamIcon : element.type === "video-meeting"
                                        ? images.videoMeetingIcon : images.availabilityIcon;
                                return (
                                    <div key={element.id} onClick={() => getSelectedElementFromId(element)}
                                        className={`element-item ${!i && "top-radius"} ${i === elements.length - 1 && "bottom-radius"}
                                    ${element.id === selectedElement?.id || element.id === selectedElement?.elementId ? "selected" : ""}`}>
                                        <div className='video-meeting-icon icon-container'>
                                            <img src={icon} alt=".." />
                                        </div>
                                        <div className={`video-meeting-title-container ${(elements.length - 1) === i && "border-0"}`}>
                                            <h6 className="element-item-title mb-0">
                                                {element.name}
                                            </h6>
                                            <div className="direction-right pe-3">
                                                <img src={images.angleRightIcon} alt="angle_right" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                </div>
            </div>
            <div className='support-button' onClick={() => handleNavigation("3", "right", "/profiledetails/support")}>
                <div className='help-icon'>
                    <img src={images.helpIcon} alt="help-icon" />
                </div>
                <label className='help-label' >Help</label>
            </div>
        </div>
    )
}
