import React from 'react'
import { images } from '../../utils/constants';
import "./SplashScreen.css"

export default function SplashScreen({message}) {
  return (
    <div className="splash-page">
    <div>
      <img alt="logo" src={images.brandLogo} />
      {!!message && <p className='mt-1'>{message}</p>}
    </div>
  </div>
  )
}
