import React, { useState } from 'react'
import "./NewElement.css"
import { images, toastError,toastSuccess } from "../../../utils/constants";
import { createElement } from '../../../apis/element';
import { Spinner } from 'reactstrap';
export default function NewElement({isSmallScreen, getElementsHandler, userLogin, handleNavigation }) {
    const [isLoading, setIsLoading] = useState(false);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const createElementHandler = (type) => {
        setIsLoading(true);
        let payload = {
            type,
            timezone
        }
        if (type === "team") {
            payload = {
                type,
                participants: [userLogin.email]
            }
        }
        createElement(payload)
            .then(({ data }) => {
                getElementsHandler(data.id, type);
                toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                toastError(err.response?.data?.message, "top-right");
            })
    }

    return (
        <>
            <div className='calendar-main-title-container'>
                <div className="back-button" onClick={() => handleNavigation("1", "right", "/")}>
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Home</label>
                </div>
                <h1 className="calendar-main-title">New element</h1>
            </div>
            <div className='new-element-container mb-3 py-1 ps-3'>
                {isLoading ? <div className="d-flex justify-content-center">
                    <Spinner color="primary" sm="sm" />
                </div> : <>

                    <div onClick={() => createElementHandler("video-meeting")}
                        className="new-element-item">
                        <div className='new-element-icon icon-container'>
                            <img src={images.videoMeetingIcon} alt=".." />
                        </div>
                        <div className="new-element-title">
                            <h6 className="new-element-item-title mb-0">
                                Video meeting
                            </h6>
                            <div className="direction-right pe-3">
                                <img src={images.addIcon} alt="angle_right" className='add-calendar-icon' />
                            </div>
                        </div>
                    </div>

                    <div onClick={() => createElementHandler("availability")}
                        className="new-element-item">
                        <div className='new-element-icon icon-container'>
                            <img src={images.availabilityIcon} alt="icon" />
                        </div>
                        <div className="new-element-title">
                            <h6 className="new-element-item-title mb-0">
                                Availability
                            </h6>
                            <div className="direction-right pe-3">
                                <img src={images.addIcon} alt="angle_right" className='add-calendar-icon' />
                            </div>
                        </div>
                    </div>

                    <div onClick={() => createElementHandler("team")} className="new-element-item">
                        <div className='new-element-icon icon-container'>
                            <img src={images.participatesIcon} alt="icon" />
                        </div>
                        <div className="new-element-title border-0">
                            <h6 className="new-element-item-title mb-0">
                                Team
                            </h6>
                            <div className="direction-right pe-3">
                                <img src={images.addIcon} alt="angle_right" className='add-calendar-icon' />
                            </div>
                        </div>
                    </div>
                </>}
            </div>

            <div className='coming-soon-title mb-1'>
                Coming soon
            </div>
            <div className='new-element-container mb-3 py-1 ps-3'>
                <div className="new-element-item">
                    <div className='new-element-icon icon-container'>
                        <img src={images.physicalMeetingIcon} alt=".." />
                    </div>
                    <div className="new-element-title">
                        <h6 className="new-element-item-title mb-0">
                            Physical meeting
                        </h6>
                    </div>
                </div>

                <div className="new-element-item">
                    <div className='new-element-icon icon-container'>
                        <img src={images.emailIcon} alt=".." />
                    </div>
                    <div className="new-element-title">
                        <h6 className="new-element-item-title mb-0">
                            Email
                        </h6>
                    </div>
                </div>

                <div className="new-element-item">
                    <div className='new-element-icon icon-container'>
                        <img src={images.formIcon} alt=".." />
                    </div>
                    <div className="new-element-title">
                        <h6 className="new-element-item-title mb-0">
                            Form
                        </h6>
                    </div>
                </div>

                <div className="new-element-item">
                    <div className='new-element-icon icon-container'>
                        <img src={images.eventIcon} alt=".." />
                    </div>
                    <div className="new-element-title">
                        <h6 className="new-element-item-title mb-0">
                            Event template
                        </h6>
                    </div>
                </div>

                <div className="new-element-item">
                    <div className='new-element-icon icon-container'>
                        <img src={images.checkedIcon} alt=".." />
                    </div>
                    <div className="new-element-title">
                        <h6 className="new-element-item-title mb-0">
                            Confirmation page
                        </h6>
                    </div>
                </div>

                <div className="new-element-item">
                    <div className='new-element-icon icon-container'>
                        <img src={images.bookingIcon} alt=".." />
                    </div>
                    <div className="new-element-title border-0">
                        <h6 className="new-element-item-title mb-0">
                            Booking page
                        </h6>
                    </div>
                </div>

            </div>
        </>
    )
}
