import React, {useEffect, useState} from 'react';
import {Col, Container, Input, Row, Spinner} from 'reactstrap';
import "./SetURL.css";
import {images, toastSuccess} from "../../utils/constants"
import {updateUser} from "../../apis/user";

export default function SetURL({isSmallScreen,userLogin, handleNavigation, setUserLogin}) {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(userLogin);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      setUser(JSON.parse(localStorage.getItem('user')));
    }
  }, [])

  const updatePersonalLinkHandler = async () => {
    if (user.link) {
      const isLinkValid = /^[a-zA-Z0-9 -]+$/.test(user.link);
      if (isLinkValid) {
        let link = user.link.split(" ").join("-");
        try {
          setIsLoading(true);
          await updateUser({name: user.name, link: link, access_token: user.accessToken, agreeToTermsAndPolicy: true});
          setUserLogin({...user, link: link});
          localStorage.setItem('user', JSON.stringify({...user, link: link}));
          if (link)
            handleNavigation("1", "left", "/");
            toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
          setIsLoading(false);
        } catch (err) {
          setError(err.response?.data.message);
          setIsLoading(false);
        }
      } else setError("You are trying to use characters not compatible with a URL");
    } else setError("URL Required");
  }

  return (
    <Container className="signin-container">
      <Row className='px-3'>
        <Col className="signin-card" xs={12} xl={5} sm={10} lg={6}>
          <div className='mb-4'>
            <img className="signin-brand-logo" alt="brand_logo" src={images.brandLogo}/>
          </div>
          <h1 className='we-cal-title'>Welcome to WeCal</h1>
          <span className='we-cal-desc'>Delightful scheduling for individuals & for teams</span>
          <label className="signup-label">YOUR WECAL URL</label>
          <div className='d-flex align-items-center w-100 m-2'>
            <span className='url'>wecal.co/</span>
            <Input
              className="input-url-link mb-0 ml-1"
              type="text" name="link"
              onChange={e => {
                setUser(a => ({...a, link: e.target.value}));
                setError('');
              }}
              value={user?.link}
              placeholder="Enter calendar link"/>
          </div>
          <p className='update-modal-link-error text-danger mb-0 mt-1'>{error}</p>

          <div className='d-flex mt-5'>
            <div className='d-flex'>
              <Input name="accept" checked={isChecked} onChange={() => setIsChecked(!isChecked)} type="checkbox"/>
            </div>
            <span className='signup-terms'>I have read and I accept the {' '}
              <a href="https://www.wecal.co/index.php/terms-and-conditions/">Terms & Condition</a> and the {' '}
              <a href="https://www.wecal.co/index.php/privacy-policy/">Privacy policy</a></span>
          </div>
          <div className="editable-button-container mt-5">
            <button type="button" className='btn-save' onClick={updatePersonalLinkHandler} disabled={!isChecked}>
              {isLoading ? <Spinner size="sm" color="primary"/> : "Save & Next"}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
