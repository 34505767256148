import React, { useEffect, useState } from "react"
import { Route, Switch, useHistory } from "react-router-dom";
import './App.css';
import { Offline, Online } from "react-detect-offline";
import HomePage from "./Container/Home/HomePage";
import { useMediaQuery } from "react-responsive";
import SignIn from "./Components/SignIn/SignIn";
import SetURL from "./Components/SetURL/SetURL";
import { ToastContainer } from "react-toastify";
import PublicPage from "./Container/Public/PublicPage";
import SplashScreen from "./Components/SplashScreen/SplashScreen";

function App() {
  const history = useHistory();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 991px)" });
  const [userLogin, setUserLogin] = useState(null);
  const [showPanel, setShowPanel] = useState("1");
  const [slideDirection, setSlideDirection] = useState("right");

  const handleWebNavigation = (pathname) => {
    if (isSmallScreen) {
      const path = pathname.split("/");
      console.log(path[1],showPanel,"first",path[1] !== 'team');
      if (path[1] !== 'profiledetails' && path[1] !== 'availability' && path[1] !== 'team' && path[1] !== 'calendar') {
        if (pathname === "/") {
          setSlideDirection("right")
          setShowPanel("1");
        } else if (path.length === 2) {
          setShowPanel("2");
        } else {
          setSlideDirection("left")
          setShowPanel("3");
        }
      }
    }
  }

  const handleNavigation = (panel, direction, path) => {
    if (panel) setShowPanel(panel);
    if (direction) setSlideDirection(direction);
    if (path) history.push(path);
  }

  return (
    <div className="body">
      <ToastContainer />
      <Offline>
        <SplashScreen message="- you are offline" />
      </Offline>
      <Online>
        <Switch>
          <Route path="/public" render={(props) =>
            <PublicPage
              slideDirection={slideDirection}
              handleNavigation={handleNavigation}
              showPanel={showPanel}
              isSmallScreen={isSmallScreen}
              {...props} />}
          />

          <Route path="/signin" exact render={(props) =>
            <SignIn
              {...props}
              userLogin={userLogin}
              setUserLogin={setUserLogin} />}
          />

          {userLogin && !userLogin?.link && <Route path="/seturl" render={(props) =>
            <SetURL
              {...props}
              userLogin={userLogin}
              isSmallScreen={isSmallScreen}
              handleNavigation={handleNavigation}
              setUserLogin={setUserLogin} />}
          />}

          <Route path="/" render={(props) =>
            <HomePage
              slideDirection={slideDirection}
              handleNavigation={handleNavigation}
              showPanel={showPanel}
              userLogin={userLogin}
              isSmallScreen={isSmallScreen}
              setUserLogin={setUserLogin}
              handleWebNavigation={handleWebNavigation}
              {...props} />} />
        </Switch>
      </Online>
    </div>
  );
}

export default App;
