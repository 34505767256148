import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { getCalendar, updateCalendar } from '../../../apis/calendars';
import {createElement, getAllAvailability,getAvailability} from '../../../apis/element';
import {images, toastError, toastSuccess} from '../../../utils/constants';
import "./AvailabilityDetails.css"
export default function AvailabilityDetails({isSmallScreen,setSelectedCalendar, handleNavigation, getElementsHandler, selectedCalendar }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allAvailability, setAllAvailability] = useState([]);
    const [selectedAvailability, setSelectedAvailability] = useState(null);

    useEffect(() => {
        getAllAvailability()
            .then(res => {
                setAllAvailability(res.data);
            })
            .catch(err => {
                toastError(err.response.data.message, "top-right");
            })
        if (selectedCalendar) {
            setSelectedAvailability({ ...selectedCalendar, id: selectedCalendar?.availabilityId });
        }
    }, [selectedCalendar])

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }
    const createAvailabilityHandler = (e) => {
        e.preventDefault();
        const payload = {
            type: "availability",
        }
        createElement(payload).then(({ data }) => {
            getAvailability(data.id, Intl.DateTimeFormat().resolvedOptions().timeZone)
            .then((res) => {
                updateAvailabilityHandler(res.data.id);
                getElementsHandler(data.id, "availability");
                }).catch(err => {
                    toastError(err.response?.data?.errors, "top-right");
                })
        }).catch(err => {
            toastError(err.response?.data?.message, "top-right");
        })
    }

    const updateAvailabilityHandler = (availabilityId = selectedAvailability.id) => {
        setIsLoading(true);
        updateCalendar(selectedCalendar.id, { availabilityId })
            .then(res => {
                getCalendar(selectedCalendar.id)
                    .then(({data}) => {
                        setSelectedAvailability({ ...data, id: data?.availabilityId });
                        setSelectedCalendar(data);
                        toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                        setIsLoading(false);
                    })
                    .catch(err => toastError(err.response.data.message, "top-right"))
            })
            .catch(err => {
                toastError(err.response.data.message, "top-right");
                setIsLoading(false);
            })
    }
    let availabilitySelected = allAvailability.find(a => a.id === selectedAvailability?.id)?.name
    if (!availabilitySelected) availabilitySelected = allAvailability.find(a => a.name === 'Default Availability')?.name;
    return (
        <section className='availability-detail'>
            <div>
                <div className='calendar-main-title-container'>
                    <div className="back-button" onClick={() => handleNavigation("2", "left", `/calendar/${selectedCalendar.name}`)}>
                        <img src={images.backIcon} alt="icon" />
                        <label className="calendar-header">Availability</label>
                    </div>
                    <h1 className="calendar-main-title">Availability</h1>
                </div>
                <h6 className='availability-label'>Availability</h6>
                <Dropdown className='availability-dropdown mb-3' isOpen={dropdownOpen} toggle={toggleDropdown} >
                    <DropdownToggle className='ps-2 d-flex' tag="div">
                        <span>{availabilitySelected}</span>
                        <div className="availability-dropdown-icon-container">
                            <img src={images.dropdownIcon} alt="icon" />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        {allAvailability.map(available => (
                            <DropdownItem key={available.id} onClick={() => setSelectedAvailability(available)}>
                                {available.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <a href="" onClick={(e) => createAvailabilityHandler(e)}>Create availability</a>
            </div>
            {selectedAvailability?.id !== selectedCalendar?.availabilityId && <div className="editable-button-container mt-4">
                <button type="button" className='btn-cancel' 
                onClick={() => setSelectedAvailability({ ...selectedCalendar, id: selectedCalendar?.availabilityId })}>Cancel</button>
                <button type="button" className='btn-save' onClick={() => updateAvailabilityHandler()} >
                    {isLoading ? <Spinner size="sm" color="primary" /> : "Save"}
                </button>
            </div>}
        </section>
    )
}
