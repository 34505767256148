import React, { useEffect, useState } from 'react'
import moment from 'moment';
import "./PublicPageCalendar.css"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { images, toastError } from '../../../utils/constants';
import { Spinner } from 'reactstrap';
import { useGoogleLogin } from 'react-google-login';
import { CLIENT_ID, SCOPES } from '../../../calendarConfig';
import { authenticateWithGoogle } from '../../../apis/auth';

export default function PublicPageCalendar({ selectedEvent, setSelectedEvent, handleNavigation, setCombineUser, slots, setSelectedMonth }) {
    const [isLoading, setIsLoading] = useState(false);
    const onSelectDateForEvent = (date) => {
        setSelectedEvent({ date: date, slot: null });
        handleNavigation("2", "right");
    }

    const handleSignIn = ({ accessToken }) => {
        setIsLoading(true);
        authenticateWithGoogle({ access_token: accessToken, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
            .then(({ user }) => {
                setCombineUser(user);
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                toastError(err, "top-right");
            })
    }

    const { signIn } = useGoogleLogin({
        clientId: CLIENT_ID, isSignedIn: false,
        prompt:'consent',
        accessType:'offline',
        responseType: "access_token", scope: SCOPES,
        onSuccess: handleSignIn, onFailure: (err) => toastError(err, "top-right"),
        onAutoLoadFinished: (res) => {
            setIsLoading(res)
        }
    })
    return (
        <section className='get-user-info-component'>
            <div>
                <h1 className="select-date-title mb-3">Select a day</h1>
                <Calendar
                    className='calendar border-0 '
                    tileDisabled={({ date }) => {
                        return !slots.some(a => moment(a[0]).format("DD MM YY") === moment(date).format("DD MM YY"))
                    }}
                    tileClassName={({ _, date }) => {
                        const isDateActive = moment(new Date()).format("DD MM YY") === moment("2022-04-22T06:00:00.000Z").format("DD MM YY");
                        const isDateSelected = moment(selectedEvent.date).format("DD MM YY") === moment(date).format("DD MM YY");
                        const isAddClass = isDateSelected && isDateActive ? "bg-active-mark" : isDateSelected ? "bg-mark" : "";
                        return (`calender-date ${isAddClass}`)
                    }}
                    calendarType="US"
                    minDetail="month"
                    minDate={new Date()}
                    onActiveStartDateChange={({ activeStartDate }) => setSelectedMonth(activeStartDate)}
                    onChange={(date) => onSelectDateForEvent(date)}
                    value={new Date()}
                    formatShortWeekday={(_, date) => moment(date).format('ddd')}
                    navigationLabel={({ date }) => <div>
                        <span>{moment(date).format('MMMM YYYY')}</span>
                        <img className="calendar-navigation-icon" src={images.backIcon} alt="prev" />
                    </div>}
                    nextLabel={<img className="calendar-navigation-icon" src={images.backIcon} alt="next" />}
                    prevLabel={<img className="calendar-navigation-icon" src={images.backIcon} alt="prev" />}
                />
            </div>
            <div className="add-calendar-combine-container">
                <button className='btn-save' onClick={signIn}>
                    {isLoading ? <Spinner size="sm" color="primary" /> : 'Combine Your Availability'}
                </button>
                <p>(Google Workspace users only)</p>
            </div>
        </section>
    )
}
