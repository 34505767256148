import React, { useState } from 'react'
import { ListGroupItem } from 'reactstrap'
import "./SelectVideoMeeting.css"
import { images } from "../../../utils/constants";
export default function SelectVideoMeeting({ handleNavigation, selectedElement, showPanel }) {
    const [googleMeeting, setGoogleMeeting] = useState(true);
    return (
        <>
            <div className="calendar-main-title-container">
                <div className="back-button" onClick={() => handleNavigation(showPanel === "2" ? "1" : "2", showPanel === "2" ? "right" : "left", showPanel === "2" ? "/" : "/newelement")}>
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Home</label>
                </div>
                <h1 className="calendar-main-title">{selectedElement?.name}</h1>
            </div>
            <ListGroupItem className="switch-container border-0 mb-3">
                <span className="switch-label" htmlFor="googleMeetSwitch">Google Meet</span>
                <div className="form-check form-switch">
                    <input
                        onChange={() => setGoogleMeeting(true)}
                        className="form-check-input m-0"
                        type="checkbox"
                        role="switch"
                        id="googleMeetSwitch" checked={googleMeeting} />
                </div>
            </ListGroupItem>
            <label className="coming-soon-title mb-1">Coming soon </label>
            <div className='meeting-item-container p-3 mb-3'>
                <div className="coming-soon-component-item d-flex align-items-center">
                    <div className='icon-container'>
                        <img src={images.physicalMeetingIcon} alt=".." />
                    </div>
                    <div className="ms-2 border-0">
                        <h6 className="coming-soon-item-title mb-0">
                            Rich location
                        </h6>
                    </div>
                </div>
            </div>
        </>
    )
}
