import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input, Row, Spinner, Table } from 'reactstrap';
import { getAvailability, updateAvailability } from '../../../apis/element';
import { calculateMinutes, images, toastError, toastSuccess, toastWarning } from '../../../utils/constants';
import CustomTimePicker from '../../CustomTimePicker/CustomTimePicker'
import "./AddTimeSlots.css";

export default function AddTimeSlots({ isSmallScreen,getElementsHandler, handleNavigation, selectedAvailability }) {
    const [slots, setSlots] = useState(convertSlots(selectedAvailability?.availabilitySlots));
    const [isLoading, setLoading] = useState(false);
    const [isShowActionButton, setIsShowActionButton] = useState(false);
    const [availabilityName, setAvailabilityName] = useState("");
    const [availabilityNameError, setAvailabilityNameError] = useState("");

    useEffect(() => {
        if (selectedAvailability) setAvailabilityName(selectedAvailability.name)
    }, [selectedAvailability])

    const handleAddSlot = (e) => {
        e.preventDefault();
        setSlots(prev => [...prev, {
            slotId: Math.random(), from: "09:30", to: "12:00",
            days: { Mon: true, Tues: true, Wed: true, Thurs: true, Fri: true, Sat: false, Sun: false }
        }]);
        setIsShowActionButton(true);
    }

    const handleDeleteSlot = (slotId) => {
        setIsShowActionButton(true);
        const list = Object.assign([], slots.filter(slot => slot.slotId !== slotId));
        setSlots(list);
    }

    const handleSlotChange = (data, slotId) => {
        const list = Object.assign([], slots);
        const index = list.findIndex(l => l.slotId === slotId);
        Object.assign(list[index], data);
        setSlots(list);
        setIsShowActionButton(true);
    }

    function convertSlots(slots) {
        if (slots?.length) {
            const newSlots = slots.map(slot => {
                const [Mon, Tues, Wed, Thurs, Fri, Sat, Sun] = slot.days.split(",");
                return ({
                    slotId: slot.id,
                    availabilityId: slot.id,
                    createdAt: slot.createdAt,
                    updatedAt: slot.updatedAt,
                    days: { Mon: !!Mon, Tues: !!Tues, Wed: !!Wed, Thurs: !!Thurs, Fri: !!Fri, Sat: !!Sat, Sun: !!Sun },
                    from: slot.from,
                    to: slot.to,
                })
            });
            return newSlots
        }
        return []
    }

    const addAvailabilitySlotsHandler = () => {
        const newSlots = slots.map(slot => {
            const newDays = Object.keys(slot.days).reduce((p, key) => {
                if (slot.days[key] === true) return { ...p, [key]: slot.days[key] }
                else return p
            }, {});
            return ({
                slotId: slot?.availabilityId ? slot?.slotId : undefined,
                from: slot.from,
                to: slot.to,
                days: Object.keys(newDays).join()
            })
        });
        const data = {
            name: availabilityName,
            id: selectedAvailability?.id,
            elementId: selectedAvailability?.elementId,
            slots: newSlots,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        if (!newSlots.length) {
            toastWarning("Add time slots!", "top-right");
        } else if (!newSlots.every(a => a.days)) {
            toastWarning("Days must be selected!", "top-right");
        } else if (!calculateMinutes(newSlots)) {
            toastWarning("Duration should be more than 30 minutes!", "top-right");
        } else if (!availabilityName) {
            setAvailabilityNameError("Availability name required!")
        } else {
            setLoading(true);
            updateAvailability(selectedAvailability?.id, data)
                .then((res) => {
                    getElementsHandler(selectedAvailability.elementId, "availability");
                    // getAvailabilityHandler();
                    setIsShowActionButton(false);
                    setLoading(false);
                    toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                }).catch(err => {
                    setLoading(false);
                    toastError(err.response?.data?.message, "top-right");
                })
        }
    }

    const handleCancelAvailabilitySlots = () => {
        setIsShowActionButton(false)
    }

    const handleAvailabilityNameChange = (name) => {

        setIsShowActionButton(true);
        setAvailabilityNameError("");
        setAvailabilityName(name);
    }


    return (
        <section className='d-flex flex-column justify-content-between time-slot-container'>
            <div className='mb-3'>
                <div className='calendar-main-title-container'>
                    <div onClick={() => handleNavigation("2", "left", "/availability")} className="back-button">
                        <img src={images.backIcon} alt="icon" />
                        <label className="calendar-header">Availability options</label>
                    </div>
                    <h1 className="calendar-main-title">Time Slots</h1>
                </div>

                <FormGroup className='mb-0'>
                    <h6 className='availability-name-label'>Availability name</h6>
                    <Input
                        className='txt-availability-name'
                        type="text" name="name"
                        placeholder="Enter availability name"
                        value={availabilityName}
                        disabled={selectedAvailability?.name === 'Default Availability'}
                        onChange={({ target }) => handleAvailabilityNameChange(target.value)}
                    />
                    <p className="formik-error mb-0">{availabilityNameError}</p>
                </FormGroup>

                {!!slots.length && <Row className='m-0'>
                    <Col xs={11} className='p-0'>
                        <Row className='m-0 py-1'>
                            <Col xs={12} md={5} lg={12} xxl={5} className='p-0' />
                            <Col xs={12} md={7} lg={12} xxl={7} className="p-0" >
                                <div className='week-days-container d-flex justify-content-between'>
                                    <label className='day-label-slot-width'>Mon</label>
                                    <label className='day-label-slot-width'>Tue</label>
                                    <label className='day-label-slot-width'>Wed</label>
                                    <label className='day-label-slot-width'>Thu</label>
                                    <label className='day-label-slot-width'>Fri</label>
                                    <label className='day-label-slot-width'>Sat</label>
                                    <label className='day-label-slot-width'>Sun</label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
                {!slots.length ? <div className='d-flex justify-content-center'><p>Please add time slot</p></div>
                    : slots.map((slot) => {
                        return (
                            <CustomTimePicker key={slot.slotId}
                                handleDeleteSlot={() => handleDeleteSlot(slot.slotId)}
                                slot={slot}
                                handleSlotChange={handleSlotChange} />
                        )
                    })}
                <div className='mt-3'>
                    <a className="btn-add-slot" href='' onClick={(e) => handleAddSlot(e)}>Add Time Slot</a>
                </div>
            </div>
            {isShowActionButton &&
                <div className="time-slot-button-container">
                    <button className='btn-cancel' onClick={handleCancelAvailabilitySlots}>Cancel</button>
                    <button className='btn-save' onClick={addAvailabilitySlotsHandler}>
                        {isLoading ? <Spinner size="sm" /> : "Save"}
                    </button>
                </div>
            }
        </section>
    )
}