import React, { useEffect, useRef, useState } from 'react'
import { Button, FormGroup, Input, Label, Spinner } from 'reactstrap'
import "./TeamDetails.css"
import { images, toastError, toastSuccess } from "../../../utils/constants";
import { updateTeam } from "../../../apis/element";

export default function TeamDetails({isSmallScreen,  getElementsHandler, handleNavigation, selectedTeam }) {
  const [teamDetail, setTeamDetail] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [teamNameError, setTeamNameError] = useState("");
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [accessErrors, setAccessErrors] = useState({});
  const { participants, name, elementId, id } = teamDetail || {};

  useEffect(() => {
    if (selectedTeam) {
      setTeamDetail(selectedTeam);
    }
  }, [selectedTeam])

  const handleTeamDetails = () => {
    if (name) {
      const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      const isVaidEmail = participants.reduce((prev, curr) => {
        if (curr.email && !emailValidation.test(curr.email)) {
          return {...prev, [curr.email] : "Email is invalid !"};
        }
        return prev
      }, null);

      if (!isVaidEmail) {
        const newParticipants = participants.reduce((prev, curr) => {
          if (!!curr.email) return [...prev, curr.email]
          return prev
        }, []);
        setIsLoadingUpdate(true);
        const payload = {
          type: "team",
          participants: newParticipants,
          name,
          elementId
        }
        updateTeam(id, payload).then(() => {
          getElementsHandler(elementId);
          setIsLoadingUpdate(false);
          toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
        }).catch(err => {
          setIsLoadingUpdate(false);
          if (err.response?.data?.message === "Permission Error") {
            const accessErrorMessage = "You do not have access to this calendar, please request access to this calendar."
            const error = err.response.data.data.reduce(
              (prev, curr) => ({ ...prev, [curr]: accessErrorMessage }), {});
            setAccessErrors(error);
          } else {
            toastError(err.response?.data?.message, "top-right");
          }
        })
      } else setEmailError(isVaidEmail);
    } else setTeamNameError("Team name is required !");
  }

  const handleCancelUpdate = () => {
    setTeamDetail(selectedTeam);
    setAccessErrors("");
    setIsAuthorized(false);
  }

  const handleChange = (e, i) => {
    setEmailError("");
    setAccessErrors("");
    const newParticipants = Object.assign([], participants);
    newParticipants[i] = { email: e.target.value };
    const isEmailEmpty = newParticipants.find(p => !p.email);
    if (!isEmailEmpty) {
      newParticipants.push({ email: '' });
    }
    setTeamDetail(p => ({ ...p, participants: newParticipants }));
  }

  const handleRemoveParticipate = (i) => {
    setEmailError("");
    setAccessErrors("");
    const newParticipants = Object.assign([], participants);
    newParticipants.splice(i, 1);
    setTeamDetail(p => ({ ...p, participants: newParticipants }));
  }
  const handleTeamNameChange = (name) => {
    setTeamNameError("");
    setAccessErrors("");
    setTeamDetail(p => ({ ...p, name }));
  }

  return (
      <div className='team-details-container d-flex flex-column justify-content-between'>
        <div>
          <div className='calendar-main-title-container'>
            <div className="back-button" onClick={() => handleNavigation("2", "left", "/team")}>
              <img src={images.backIcon} alt="icon" />
              <label className="calendar-header">Team</label>
            </div>
            <h1 className="calendar-main-title">Team</h1>
          </div>
          <FormGroup className='mb-0'>
            <h6 className='team-name-label'>Team Name</h6>
            <Input
              className='txt-team-name'
              type="text" name="name"
              placeholder="Enter Team name"
              value={name}
              onChange={({ target }) => handleTeamNameChange(target.value)}
            />
            <p className="formik-error mb-0">{teamNameError}</p>
          </FormGroup>
          <h6 className='team-name-label'>Participants</h6>
          <div className='participants-container'>
            {participants?.length &&
              participants.map((participate, i, arr) => (
                <FormGroup key={i} className='participants-content d-flex flex-column'>
                  <div className='d-flex' >
                    <Input
                      readOnly={i === 0}
                      className='txt-team-participants'
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      value={participate.email}
                      onChange={(e) => handleChange(e, i)}
                    />
                    {i !== 0 && arr.length - 1 !== i &&
                      <img alt="close" className="btn-close-icon"
                        src={images.closeIcon}
                        onClick={() => handleRemoveParticipate(i)}
                      />}
                  </div>
                  <p className="formik-error mt-1 mb-0">{emailError[participate.email] || accessErrors[participate.email]}</p>
                </FormGroup>
              ))}
          </div>
        </div>
        <div className='team-confirmation-container'>
          <FormGroup check className=''>
            <Input
              id="authorized"
              name="authorized"
              type="checkbox"
              checked={isAuthorized}
              value={isAuthorized}
              onChange={() => setIsAuthorized(!isAuthorized)}
            />
            <Label check for="authorized">
              I confirm that I am authorized to access and use the calendars above
            </Label>
          </FormGroup>
          <div className="team-button-container">
            <button type="button" className='btn-cancel' onClick={handleCancelUpdate} >Cancel</button>
            <button type="button" disabled={!isAuthorized} className='btn-save' onClick={handleTeamDetails} >
              {isLoadingUpdate ? <Spinner size="sm" color="primary" /> : "Save"}
            </button>
          </div>
        </div>
      </div>
  )
}
