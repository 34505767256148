import { useState } from "react";
import { Input, Spinner } from "reactstrap";
import { supportRequest } from "../../../apis/user";
import { images, toastSuccess } from "../../../utils/constants";
import "./support.css";

export default function UserDetails({isSmallScreen, handleNavigation }) {
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const supportRequestHandler = () => {
        setIsLoading(true);
        supportRequest(description)
            .then(res => {
                setIsLoading(false);
                handleNavigation('2', 'left', '/profiledetails');
                toastSuccess("", isSmallScreen ? "bottom-right" : "top-right");
                // toastSuccess("Your message has been sent, we will get back to you soon.", 'top-right');
            })
            .catch(err => {
                setError(err.response.data.message);
                setIsLoading(false);
            })
    }
    return (
        <>
            <div className='calendar-main-title-container'>
                <div className="back-button" onClick={() => handleNavigation("2", "left")}>
                    <img src={images.backIcon} alt="icon" />
                    <label className="calendar-header">Profile details</label>
                </div>
                <div className="d-flex justify-content-between">
                    <h1 className="calendar-main-title">Support Request</h1>
                </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center p-3">
                <img src={images.Support} alt="support_icon" className="img-fluid support-img" />
            </div> */}
            <Input
                type="textarea"
                name="description"
                className='description-textarea'
                placeholder="What can we help you with?"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
            />
            <p className="formik-error">{error}</p>

            <p className="info-text">You can also contact WeCal’s support at <a href="contact@wecal.co">contact@wecal.co</a></p>
            <div className="editable-button-container">
                <button type="button" className='btn-cancel'
                onClick={() => handleNavigation('2', 'left', '/profiledetails')} >Cancel</button>
                <button type="button" className='btn-save' onClick={supportRequestHandler} disabled={!description} >
                    {isLoading ? <Spinner size="sm" color="primary" /> : "Send"}
                </button>
            </div>
        </>
    )
}
