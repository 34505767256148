import React, { useEffect, useRef, useState } from 'react'
import { FormGroup, Input, Label, Spinner } from 'reactstrap'
import moment from "moment";
import "./PublicPageEmailEvents.css"
import { images, toastError} from '../../../utils/constants';
import { useLocation } from 'react-router-dom';
import { createPublicCalendarEvent } from '../../../apis/calendars';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validations = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required')
});

export default function PublicPageEmailEvents({ selectedEvent, handleNavigation, setSelectedEvent, setIsReqSlot, publicCalendar }) {
    const [value] = useState({ name: "", email: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmedRequest, setIsConfirmedRequest] = useState(false);
    const { pathname } = useLocation();
    const formikRef = useRef(null);

    const handleGoBack = () => {
        handleNavigation("2", "left");
        setSelectedEvent(prev => ({ ...prev, date: selectedEvent.slot[0], filter: true, slot: null }));
    }

    const handleSaveEmail = (values) => {
        const link = pathname.split("/")
        const data = {
            "userLink": link[3],
            "calendarLink": link[4],
            "email": values.email,
            "name": values.name,
            "companyLink": link[2],
            "startDateTime": selectedEvent.slot[0],
            "endDateTime": selectedEvent.slot[1]
        }
        setIsLoading(true);
        createPublicCalendarEvent(data)
            .then(() => {
                setIsReqSlot();
                formikRef.current.resetForm();
                setIsLoading(false);
                setIsConfirmedRequest(true);
            }).catch(err => {
                setIsLoading(false);
                toastError(err.response?.data.message, 'top-right');
            })
    }

    return (
        <>
            {isConfirmedRequest ?
                <section className='get-user-info-component'>
                    <h1 className="calendar-main-title mb-4">Meeting Confirmed</h1>
                    <span className='event-message col'> 
                    You are scheduled for 
                    <span className='mx-1'>{moment(selectedEvent.date).format("dddd DD MMMM YYYY")}</span> 
                    <span >{new Date(selectedEvent.slot[0]).toLocaleTimeString('en', { timeStyle: 'short', hour12: false })} - 
                    {moment(new Date(selectedEvent.slot[0])).add(+publicCalendar?.duration, 'm').format('HH:mm')}
                    </span>
                    . A calendar invitation has been sent to your email address.
                    </span>
                    <div className="add-email-button-container">
                        <button className='btn-save' onClick={handleGoBack} >
                            {isLoading ? <Spinner size="sm" color="primary" /> : 'Go back'}
                        </button>
                    </div>
                </section>
                :
                <Formik
                    initialValues={{ name: value.name, email: value.email }}
                    enableReinitialize={true}
                    innerRef={formikRef}
                    onSubmit={(values) => handleSaveEmail(values)}
                    validationSchema={validations}>
                    {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
                        <>
                            <section className='get-user-info-component'>
                                <div>
                                    <div className="back-button" onClick={handleGoBack}>
                                        <img src={images.backIcon} alt="back_icon" />
                                        <label className="calendar-header">Slots</label>
                                    </div>
                                    <h1 className="calendar-main-title mb-4">
                                        {moment(selectedEvent.date).format("dddd DD MMMM YYYY")}
                                    </h1>
                                    <div className='slot-item-container' onClick={() => setSelectedEvent(prev => ({ ...prev, slot: null }))}>
                                        <div className="slot-item">
                                            <div className='slot-icon icon-container'>
                                                <img src={images.backIcon} alt="timer_icon" />
                                            </div>
                                            <h6 className="slot-title mb-0">{new Date(selectedEvent.slot[0]).toLocaleTimeString('en', { timeStyle: 'short', hour12: false })} - {moment(new Date(selectedEvent.slot[0])).add(+publicCalendar?.duration, 'm').format('HH:mm')}</h6>
                                        </div>
                                    </div>

                                    <FormGroup>
                                        <Label className="lbl-email" for="email">Your Name</Label>
                                        <Input
                                            className='txt-email'
                                            type="name" name="name"
                                            id="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            placeholder="Enter Your Name" />
                                        <p className='email-error text-danger mt-1'>{errors.name && touched.name && errors.name}</p>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="lbl-email" for="email">EMAIL</Label>
                                        <Input
                                            className='txt-email'
                                            type="email"
                                            name="email"
                                            id="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            placeholder="Enter email" />
                                        <p className='email-error text-danger mt-1'>{errors.email && touched.email && errors.email}</p>
                                    </FormGroup>

                                </div>
                                <div className="add-email-button-container">
                                    <button className='btn-save' onClick={handleSubmit} >
                                        {isLoading ? <Spinner size="sm" color="primary" /> : 'Confirm Event'}
                                    </button>
                                </div>
                            </section >
                        </>
                    )}
                </Formik >
            }
        </>
    )
}
