import React, { useState, useEffect, useMemo } from 'react'
import "./PublicPage.css";
import { Switch, Route, useLocation } from "react-router-dom";
import Slide from 'react-reveal/Slide';
import SplashScreen from '../../Components/SplashScreen/SplashScreen';
import PublicPageSidebar from "../../Components/Public/PublicPageSidebar/PublicPageSidebar"
import PublicPageCalendar from "../../Components/Public/PublicPageCalendar/PublicPageCalendar"
import PublicPageFreeSlots from "../../Components/Public/PublicPageSlots/PublicPageFreeSlots";
import PublicPageEmailEvents from "../../Components/Public/PublicPageEmail/PublicPageEmailEvents";
import { getPublicCalendar, getPublicCalendarSlots, getTimeZones } from "../../apis/calendars";
import { Col, Row, Spinner } from 'reactstrap';
import { debounce, toastError } from '../../utils/constants';
import moment from 'moment';

export default function PublicPage({ handleNavigation, showPanel, slideDirection, isSmallScreen }) {
  const [slots, setSlots] = useState([]);
  const [filterSlots, setFilterSlots] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [searchTimezone, setSearchTimezone] = useState("");
  const [isSlotLoading, setIsSlotLoading] = useState(false);
  const [publicCalendar, setPublicCalendar] = useState({ duration: 0, timezone: '', description: '', location: '', name: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isCombineAvailability, setIsCombineAvailability] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [selectedEvent, setSelectedEvent] = useState({ date: null, filter: false, slot: null });
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [splashMessage, setSplashMessage] = useState("");
  const [combineUser, setCombineUser] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    setIsLoading(true);
    const link = pathname.split("/");
    getPublicCalendar({ userLink: link[3], companyLink: link[2], calendarLink: link[4] })
      .then(res => {
        setPublicCalendar(res.data);
        setSelectedTimeZone(localStorage.getItem('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone)
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response.data.message === 'Invalid User.' || err.response.data.message === "Invalid Calendar.") setSplashMessage(err.response.data.message);
        toastError(err.response?.data?.message, "top-right");
      })
    getTimeZonesHandler();
  }, []);

  const getTimeZonesHandler = (name = '') => {
    getTimeZones(name)
      .then(res => setTimezones(res.data))
      .catch(err => toastError(err.response?.data?.message, "top-right"))
  }

  const searchTimeZones = useMemo(() => debounce((name) => {
    getTimeZonesHandler(name);
  }, 1000), []);

  const searchTimeZoneHandler = async (value) => {
    await searchTimeZones(value);
    setSearchTimezone(value);
  }

  useEffect(() => {
    getPublicCalendarSlotsHandler();
  }, [selectedTimeZone, selectedMonth, combineUser]);

  const getPublicCalendarSlotsHandler = () => {
    setIsSlotLoading(true);
    if (selectedTimeZone && selectedMonth) {
      const link = pathname.split("/");
      const data = {
        userLink: link[3],
        companyLink: link[2],
        calendarLink: link[4],
        date: selectedMonth,
        timezone: selectedTimeZone,
        attendee: combineUser ? { accessToken: combineUser.accessToken, email: combineUser.email } : null
      }
      getPublicCalendarSlots(data)
        .then(res => {
          setSlots(res.data);
          setSearchTimezone("");
          getTimeZonesHandler();
          setIsSlotLoading(false);
        })
        .catch(err => {
          setIsSlotLoading(false);
          if (err.response.data.message === 'Invalid User.' || err.response.data.message === "Invalid Calendar.") setSplashMessage(err.response.data.message);
          toastError(err.response.data.message, "bottom-right");
        });
    }
  }

  useEffect(() => {
    if (selectedEvent.date) {
      let filterSlot = slots;
      if (selectedEvent?.filter) {
        filterSlot = slots.filter(s => s[0] !== selectedEvent.date);
      }
      filterSlot = filterSlot.filter(s => {
        return moment(s[0]).isSame(moment(selectedEvent.date).format('YYYY-MM-DD'), 'day')
      });
      setFilterSlots(filterSlot);
    }
  }, [selectedEvent]);

  return (
    <>
      {!!splashMessage ?
        <SplashScreen message={splashMessage} />
        : isLoading ?
          <div className='d-flex justify-content-center py-3'>
            <Spinner color="primary" />
          </div>
          :
          <Row className="public-page-container m-0">
            <Col xs={12} lg={4} className={`border-end public-page-panel d-lg-flex ${showPanel === "1" ? "d-flex" : "d-none"}`}>
              <Slide left duration={200} when={!isSmallScreen || showPanel === "1"}>
                <section>
                  <PublicPageSidebar
                    publicCalendar={publicCalendar}
                    setSearchTimezone={searchTimeZoneHandler}
                    searchTimezone={searchTimezone}
                    timeZones={timezones}
                    selectedTimeZone={selectedTimeZone}
                    setSelectedTimeZone={setSelectedTimeZone}
                  />
                  {isSmallScreen &&
                    <Route path="/" render={(props) =>
                      <PublicPageCalendar  {...props}
                        setSelectedMonth={setSelectedMonth}
                        setCombineUser={setCombineUser}
                        selectedMonth={selectedMonth}
                        selectedEvent={selectedEvent}
                        slots={slots}
                        handleNavigation={handleNavigation}
                        setSelectedEvent={setSelectedEvent}
                      />} />
                  }
                </section>
              </Slide>
            </Col>
            <Col xs={12} lg={4} className={`border-end public-page-panel d-lg-flex ${showPanel === "2" ? "d-flex" : "d-none"}`}>
              <Slide when={!isSmallScreen || showPanel === "2"}
                right={slideDirection === "right"}
                left={slideDirection === "left"}
                duration={200} >
                <section>
                  <Switch>
                    {!isSmallScreen ?
                      <Route path="/" render={(props) =>
                        <PublicPageCalendar  {...props}
                          setSelectedMonth={setSelectedMonth}
                          setCombineUser={setCombineUser}
                          selectedMonth={selectedMonth}
                          selectedEvent={selectedEvent}
                          handleNavigation={handleNavigation}
                          slots={slots}
                          setSelectedEvent={setSelectedEvent}
                        />} />
                      :
                      <Route path="/" render={(props) =>
                        <PublicPageFreeSlots {...props}
                          freeSlots={filterSlots}
                          freeSlotsLoading={isSlotLoading}
                          setSelectedEvent={setSelectedEvent}
                          selectedTimeZone={selectedTimeZone}
                          handleNavigation={handleNavigation}
                          selectedEvent={selectedEvent}
                        />} />
                    }
                  </Switch>
                </section>
              </Slide>
            </Col>
            <Col xs={12} lg={4} className={`public-page-panel d-lg-flex ${showPanel === "3" ? "d-flex" : "d-none"}`}>
              <Slide when={!isSmallScreen || showPanel === "3"} right duration={200} >
                <section>
                  <Switch>
                    {selectedEvent.slot ?
                      <Route
                        path="/"
                        render={(props) =>
                          <PublicPageEmailEvents {...props}
                          isSmallScreen={isSmallScreen}
                            publicCalendar={publicCalendar}
                            setSelectedEvent={setSelectedEvent}
                            setIsReqSlot={getPublicCalendarSlotsHandler}
                            handleNavigation={handleNavigation}
                            selectedEvent={selectedEvent} />
                        } /> :
                      !isSmallScreen && selectedEvent.date &&
                      <Route path="/" render={(props) =>
                        <PublicPageFreeSlots {...props}
                          freeSlots={filterSlots}
                          freeSlotsLoading={isSlotLoading}
                          setSelectedEvent={setSelectedEvent}
                          selectedTimeZone={selectedTimeZone}
                          handleNavigation={handleNavigation}
                          selectedEvent={selectedEvent}
                        />
                      } />
                    }
                  </Switch>
                </section>
              </Slide>
            </Col>
          </Row>
      }
    </>
  )
}