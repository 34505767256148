import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import "./SignIn.css";
import {images, toastError} from "../../utils/constants"
import {useGoogleLogin} from 'react-google-login';
import {authenticateWithGoogle} from "../../apis/auth";
import {CLIENT_ID, SCOPES} from "../../calendarConfig";
import {Redirect, useHistory} from 'react-router-dom';
import SplashScreen from '../SplashScreen/SplashScreen';

export default function SignIn({userLogin, setUserLogin}) {
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(true);
  const history = useHistory();

  const handleSignIn = ({code, accessToken}) => {
    setIsUserLoading(true);
    authenticateWithGoogle({code, accessToken, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
      .then(({user}) => {
        console.log(user);
        setUserLogin(user)
        localStorage.setItem('user', JSON.stringify(user));
        setIsUserLoading(false);
        if (!user.link)
          history.push('seturl');
      }).catch(err => {
      console.log(err);
      localStorage.clear();
      setIsUserLoading(false);
      toastError(err, "top-right");
    })
  }
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user && !isUserLoading && !isSignedIn) {
      handleSignIn(JSON.parse(user));
    }
  }, [isUserLoading, isSignedIn]);

  const {signIn} = useGoogleLogin({
    clientId: CLIENT_ID,
    isSignedIn: true,
    // accessType: 'offline',
    responseType: "code",
    // prompt: 'consent',
    scope: SCOPES,
    redirectUri:  process.env.NODE_ENV === 'production' ? 'https://app.wecal.co' : 'http://localhost:3000',
    onSuccess: handleSignIn,
    onFailure: (err) => toastError(err, "top-right"),
    onAutoLoadFinished: (res) => {
      setIsSignedIn(res);
      setIsUserLoading(res);
    }
  })

  if (!isUserLoading && userLogin && userLogin.link) {
    return <Redirect from="/signin" to="/"/>
  }

  return (
    <>
      {isUserLoading ?
        <SplashScreen/>
        :
        <Container className="signin-container">
          <Row className='px-3'>
            <Col className="signin-card" xs={12} xl={4} sm={10} lg={6}>
              <div className='mb-4'>
                <img className="signin-brand-logo" alt="brand_logo" src={images.brandLogo}/>
              </div>
              <div onClick={signIn} className="signin-button">
                <div>
                  <img className="google-logo" src={images.googleIcon} alt="google_logo"/>
                </div>
                <label className="ms-3 mb-0 signin-label">Sign in with Google</label>
              </div>
            </Col>
          </Row>
        </Container>}
    </>
  )
}
