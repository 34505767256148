export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.wecal.co/api/v1' : 'http://localhost:4000/api/v1';
// export const BASE_URL = 'https://api.wecal.co/api/v1';
export const URLS = {
  GOOGLE_AUTH: BASE_URL + '/authentication/google/token',
  CALENDARS: BASE_URL + '/calendars',
  ELEMENT: BASE_URL + '/elements',
  UPDATE_USER: BASE_URL + '/users/update',
  PUBLIC_CALENDAR: BASE_URL + '/calendars/getPublicCalendar',
  PUBLIC_CALENDAR_SLOTS: BASE_URL + '/calendars/slots',
  PUBLIC_CALENDAR_EVENT: BASE_URL + '/calendars/events',
  AVAILABILITY: BASE_URL + '/availability',
  TEAM: BASE_URL + '/teams',
  GET_TIMEZON: BASE_URL + '/calendars/timezones',
  SUPPORT: BASE_URL + '/users/support-request',
}
